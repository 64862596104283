var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'name',
    'quantity',
    'unit_of_time',
    'note' ],"formats":{
    quantity: 'Integer4',
    unit_of_time: 'Select*',
  },"itemsPerPage":"10","searchOnStart":true,"table":"payment_types"}})}
var staticRenderFns = []

export { render, staticRenderFns }